<template>
    <!-- TODO: Refactor AddLocationForm to be like Note CRUD. -->
    <BasicModal
        size="lg"
        :show="confirmAddModal.isOpen"
        @close="onAddCancel"
    >
        <AddLocationForm
            :form="form"
            :isBusy="isBusy || !isEditing"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Location"
        confirmationText="Are you sure you want to delete this location?  This action cannot be undone."
        :open="confirmDeleteModal.isOpen"
        @delete="onDeleteLocation"
        @cancel="onConfirmDeleteCancel"
        @close="onConfirmDeleteCancel"
    />

    <DataManagerContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <div
                        class="grid grid-cols-2 sm:grid-rows-3 w-full"
                        :class="
                            maxLocationsExceeded
                                ? 'h-50 lg:h-36'
                                : 'h-36 lg:h-28'
                        "
                    >
                        <div class="col-span-2">
                            <PageDescription>
                                View, graph, delete, and add locations. Sort
                                locations by clicking on a column header, or
                                search by Location Hierarchy, Location Name, or
                                Datalogger Serial by typing in the search boxes.
                            </PageDescription>
                        </div>
                        <div
                            class="w-full col-span-2 sm:row-span-3 grid grid-cols-1 grid-rows-3"
                        >
                            <div class="col-span-2 sm:col-start-2 row-start-1">
                                <ModalButton
                                    v-if="isNotDataAnalyst"
                                    :disabled="maxLocationsExceeded"
                                    theme="primary"
                                    label="Add Location"
                                    :busy="isLoading || isBusy"
                                    @click="onShowAdd"
                                    class="float-right"
                                />
                            </div>
                            <div
                                v-if="isNARADisabled"
                                class="col-span-2 sm:col-start-2 row-start-2"
                            >
                                <p
                                    class="text-center sm:text-right text-sm text-gray-600"
                                    :class="
                                        maxLocationsExceeded
                                            ? 'pt-4'
                                            : ' sm:pt-6'
                                    "
                                >
                                    This subscription is using
                                    {{ numLocations }} out of
                                    {{ maxLocations }} locations.
                                </p>
                            </div>
                            <div
                                v-if="isNARADisabled && maxLocationsExceeded"
                                class="col-span-2 sm:col-start-2 row-start-2sm:row-start-2 text-center sm:text-right text-sm text-red-500 sm:pl-12"
                            >
                                <p>
                                    Your organization is using the maximum number of
                                    locations available. Upgrade to a higher
                                    subscription level to increase your location
                                    quota.
                                </p>
                            </div>
                        </div>
                    </div>
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading || isSaving">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="paginationSize"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :rowHeight="null"
                        :defaultColDef="defaultColDef"
                        :getRowNodeId="useLocationAsRowNodeId"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No locations to display."
                    ></AgGridVue>
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </DataManagerContainer>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        ref,
        onBeforeMount,
        watch,
        onMounted,
    } from 'vue';
    import { useStore } from 'vuex';
    import { computedEager } from '@vueuse/core';
    import router from '@/router';

    // <!-- TYPES -->
    /** @typedef {globalThis.Account.Model} AccountResource */
    /** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';
    import { formatISO } from 'date-fns';
    import { DateTimeISO } from '@/utils/datetime';
    import { DynamicEnumFactory } from '@/utils/DynamicEnum';
    import { Maybe } from 'true-myth/dist/maybe';
    import { formatTimeZone } from '@/utils/formatters';

    // <!-- COMPONENTS -->
    import BasicModal from '@/components/BasicModal.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@components/PageIntro.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import DataManagerContainer from '~DataManager/components/wrappers/DataManagerContainer.vue';
    import LocationTableIcons from '~DataManager/components/cell/LocationTableIcons.vue';
    import AddLocationForm from '~DataManager/components/form/AddLocationForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import useAgGridVue from '@/hooks/useAgGridVue';
    import {
        LocationFormProps,
        useLocationForm,
    } from '~DataManager/hooks/useLocationForm';
    import { useLocationIndex } from '@/hooks/cache/useLocationIndex';
    import { useNARAStandardsData } from '../hooks/useNARAStandardOptions';
    import { useDataAnalystGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features';
    import { useCaseInsensitiveLocaleCompare } from '@/utils/sorters';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DataManager',
        components: {
            PageWrapper,
            PageDescription,
            PageIntro,
            AgGridVue,
            ModalButton,
            BasicModal,
            AddLocationForm,
            LoadingWrapper,
            ConfirmDeleteModal,
            DataManagerContainer,
        },
        setup(props, context) {
            // PROPS (FORM)
            const formProps = new LocationFormProps(props);
            formProps.onInit = async () => {
                targetLocation.value = null;
                form.methods.closeFormModals();
            };
            formProps.onShowAdd = async () => {
                targetLocation.value = null;
            };
            formProps.onAddCancel = async () => {
                targetLocation.value = null;
            };
            formProps.onSave = async () => {
                await refreshLocationIndex(true);
            };
            formProps.onCreate = async () => {
                form.methods.closeModal(confirmAddModal);
                targetLocation.value = null;
                await refreshLocationIndex(true);
            };
            formProps.onDelete = async () => {
                form.methods.closeModal(confirmDeleteModal);
                console.warn(
                    `Soft deleted Location '${targetLocation.value.name} [${targetLocation.value.id}]'.`
                );
                targetLocation.value = null;
                await refreshLocationIndex(true);
            };
            const form = useLocationForm(formProps, context);
            const store = useStore();
            const { isNotDataAnalyst } = useDataAnalystGate({ store });

            // STATE (FORM)
            const { isFetching, cache, locations, refreshLocationIndex } =
                useLocationIndex(form.cache);

            // STATE
            const {
                cleanLocationDetails,
                dirtyLocationDetails,
                confirmAddModal,
                confirmDeleteModal,
                targetLocation,
            } = form.state;

            /** @type {Readonly<Vue.Ref<Account.Model>>} */
            const currentAccount = computedEager(() => {
                return store?.state?.accounts?.account;
            });

            /** @type {Readonly<Vue.Ref<TimeZoneIdentifier>>} */
            const displayTimezone = computedEager(() => {
                return currentAccount?.value?.timezone ?? 'UTC';
            });

            const user = computed(() => {
                return store?.state?.users?.me;
            });

            // STATUS (FORM)
            const { isBusy, isDeleting, isEditing, isRefreshing, isSaving } =
                form.properties;

            // EVENT HANDLERS
            const {
                onReset,
                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDelete: onDeleteLocation,

                onShowAdd,
                onAddCancel,
            } = form.handlers;

            const updateLocationQuota = () => {
                numLocations.value =
                    currentOrganization.value.numberOfLocations ?? 0;
            };

            /**
             * Format the date value for the handled field.
             * @type {AgGrid.ValueFormatterFunc<string>}
             */
            const formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) =>
                    DateTimeISO.parse(dt)
                );
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca', {
                            timeZone: displayTimezone.value,
                        });
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };

            // Computed property definition.
            const timeZoneAbbreviation = computed(() => {
                const timezone = displayTimezone.value;
                const formatted = formatTimeZone(timezone);
                return formatted;
            });

            // STATE (INDEX)
            /** @type {Vue.Ref<Number>} */
            const paginationSize = ref(25);

            // PROPERTIES (INDEX)

            /**
             * @type {Vue.ComputedRef<Boolean>}
             */
            const isLoading = computed(() => {
                return (
                    cache.notes.value.has.status('fetching') ||
                    cache.notes.value.has.status('caching') ||
                    isFetching.value
                );
            });

            /**
             * @type {Vue.ComputedRef<LocationResource[]>}
             */
            const orgLocations = computed(() => locations.value);

            const numLocations = ref(null);

            const currentOrganization = computedEager(() => {
                return store?.state?.accounts?.organization;
            });

            const currentSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.currentSubscription;
            });

            const pendingSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.pendingSubscription;
            });

            const maxLocations = computed(() => {
                return currentSubscription.value?.maxLocations ?? Infinity;
            });

            const maxLocationsExceeded = computed(() => {
                return numLocations.value >= maxLocations.value;
            });

            const { isNARADisabled } = useNARAFeature();

            /** Column field keys. */
            const colFields = DynamicEnumFactory().fromKeys(
                /** @type {const} */ ([
                    'id',
                    'name',
                    'path',
                    'maxDate',
                    'lastUploadDate',
                    'dataLoggerManufacturer',
                    'dataLoggerSerialNumber',
                    'dateUpdated',
                ])
            );

            // Get the case-insensitive sorting algorithm.
            const comparators = {
                byLocale: useCaseInsensitiveLocaleCompare(),
                /**
                 * Sort by the hierarchy value.
                 * @param {string | null} valueA The left-hand value to compare.
                 * @param {string | null} valueB The right-hand value to compare.
                 * @param {AgGrid.RowNode} nodeA The left-hand `RowNode` context.
                 * @param {AgGrid.RowNode} nodeB The right-hand `RowNode` context.
                 * @param {boolean} isDescending `true` if sort direction is `desc`. Not to be used for inverting the return value as the grid already applies `asc` or `desc` ordering.
                 * @returns {integer}
                 */
                byHierarchy: (valueA, valueB, nodeA, nodeB, isDescending) => {
                    // Prepare first value.
                    const a = { value: valueA, unassigned: true };
                    a.unassigned = nodeA.data?.hierarchyId == null;
                    a.value = a.unassigned ? '<Unassigned>' : valueA;

                    // Prepare the second value.
                    const b = { value: valueB, unassigned: true };
                    b.unassigned = nodeB.data?.hierarchyId == null;
                    b.value = b.unassigned ? '<Unassigned>' : valueB;

                    // If both are comparable, compare names:
                    if (a.unassigned === b.unassigned) {
                        return comparators.byLocale(a.value, b.value);
                    }

                    // If left is unassigned by right is assigned, then B < A.
                    if (
                        a.unassigned !== b.unassigned &&
                        a.unassigned === true
                    ) {
                        return 1;
                    }

                    // If right is unassigned by left is assigned, then B < A.
                    if (
                        a.unassigned !== b.unassigned &&
                        b.unassigned === true
                    ) {
                        return -1;
                    }

                    // By default.
                    return comparators.byLocale(a.value, b.value);
                },
            };

            /** @type {Record<typeof colFields[keyof colFields], AgGrid.ColumnDef>} Column schema. */
            const colSchema = {
                [colFields.id]: {
                    headerName: '',
                    field: colFields.id,
                    cellRendererFramework: LocationTableIcons,
                    lockPosition: true,
                    resizable: true,
                    minWidth: 120,
                    filter: false,
                    cellRendererParams: {
                        handleView: (event, id) => {
                            changeRoute(id);
                        },
                        handleAnalysis: (event, id) => {
                            //FIXME: This route URL may change?
                            router.push(`/analysis?location=${id}`);
                        },
                        handleDelete: (event, id) => {
                            const target = orgLocations.value.find(
                                (location) => location.id == id
                            );
                            const { id: location, name } = target ?? {
                                id: null,
                                name: null,
                            };
                            if (!!location) {
                                targetLocation.value = {
                                    id: location,
                                    name: name,
                                };
                                onShowConfirmDelete();
                            } else {
                                console.error(
                                    'Failed to select matching location.'
                                );
                            }
                        },
                    },
                },
                [colFields.name]: {
                    headerName: 'Location Name',
                    field: colFields.name,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No name provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.name,
                    },
                    wrapText: true,
                    autoHeight: true,
                    minWidth: 310,
                    sort: 'asc',
                    sortingOrder: ['asc', 'desc'],
                    comparator: comparators.byLocale,
                },
                [colFields.path]: {
                    headerName: 'Location Hierarchy',
                    field: colFields.path,
                    cellRenderer: (params) => {
                        const segments =
                            !!params?.value && params.value !== ''
                                ? params.value.split('/')
                                : getAccountHierarchyLabels();
                        return segments.join(' / ');
                    },
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.path,
                    },
                    wrapText: true,
                    autoHeight: true,
                    minWidth: 330,
                    sort: 'asc',
                    sortingOrder: ['asc', 'desc'],
                    comparator: comparators.byHierarchy,
                },
                [colFields.maxDate]: {
                    headerName: `Data End (${timeZoneAbbreviation.value})`,
                    field: 'maxDate',
                    valueFormatter: formatDate,
                    // cellRenderer: (params) =>
                    //     !!params.value && params.value !== ''
                    //         ? useDateComponentFormat(params)
                    //         : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.maxDate,
                    },
                    filter: false,
                    minWidth: 150,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    headerClass: 'whitespace-normal text-center',
                },
                [colFields.lastUploadDate]: {
                    headerName: `Last Upload Date (${timeZoneAbbreviation.value})`,
                    field: 'lastUploadDate',
                    valueFormatter: formatDate,
                    // cellRenderer: (params) =>
                    //     !!params.value && params.value !== ''
                    //         ? useDateComponentFormat(params)
                    //         : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) =>
                            !params.data?.lastUploadDate,
                    },
                    filter: false,
                    minWidth: 80,
                },
                [colFields.dataLoggerManufacturer]: {
                    headerName: 'Datalogger Mfr.',
                    field: colFields.dataLoggerManufacturer,
                    minWidth: 200,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No manufacturer provided.',
                    cellClassRules: {
                        'text-gray-400': (params) =>
                            !params.data?.dataLoggerManufacturer,
                    },
                },
                [colFields.dataLoggerSerialNumber]: {
                    headerName: 'Datalogger Serial',
                    field: colFields.dataLoggerSerialNumber,
                    wrapText: true,
                    autoHeight: true,
                    maxWidth: 150,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No serial number.',
                    cellClassRules: {
                        'text-gray-400': (params) =>
                            !params.data?.dataLoggerSerialNumber,
                    },
                },
                [colFields.dateUpdated]: {
                    headerName: `Last Updated (${timeZoneAbbreviation.value})`,
                    field: 'dateUpdated',
                    valueFormatter: formatDate,
                    // cellRenderer: (params) =>
                    //     !!params.value && params.value !== ''
                    //         ? useDateComponentFormat(params)
                    //         : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.dateUpdated,
                    },
                    filter: false,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    minWidth: 120,
                    headerClass: 'whitespace-normal text-center',
                },
            };

            /** @type {AgGrid.ColumnDef[]} Ordered column definitions. */
            const colDefs = [
                colSchema.id,
                colSchema.path,
                colSchema.name,
                colSchema.dataLoggerSerialNumber,
                colSchema.maxDate,
                colSchema.dateUpdated,
            ];

            /** @type {Vue.ComputedRef<LocationResource[]>} Row data made up of location resources. */
            const rowData = computed(() => {
                const indexedLocations = orgLocations.value;
                return indexedLocations.length <= 0 ? [] : indexedLocations;
            });

            // METHODS (INDEX)

            /* Functions */
            const { onGridReady, onColumnResized, defaultColDef } =
                useAgGridVue();

            /** @type {() => Account.Model} */
            const getAccount = () => store?.state?.accounts?.account;

            const getAccountHierarchyLabels = () => {
                const defaultLabels = ['Site', 'Building', 'Floor', 'Room'];
                const accountLabels = getAccount()?.treeLabels ?? null;
                return accountLabels ?? defaultLabels;
            };

            const useLocationAsRowNodeId = (data) => {
                return data.id;
            };

            const changeRoute = (id) => {
                router.push(`/data-manager/locations/${id}`);
            };

            const { fetchData: fetchNARAStandardData } =
                useNARAStandardsData(store);

            /* Lifecycle methods */
            onBeforeMount(async () => {
                await refreshLocationIndex(true);
                await fetchNARAStandardData();
                updateLocationQuota();
            });

            onMounted(() => {
                watch(
                    () => orgLocations?.value?.length,
                    (current, previous) => {
                        updateLocationQuota();
                    }
                );
            });

            return {
                form,
                orgLocations,
                numLocations,
                maxLocations,
                maxLocationsExceeded,
                targetLocation,

                isLoading,
                isBusy,
                isDeleting,
                isEditing,
                isRefreshing,
                isSaving,
                isNARADisabled,

                cleanLocationDetails,
                dirtyLocationDetails,

                confirmAddModal,
                confirmDeleteModal,

                rowData,
                colDefs,
                defaultColDef,
                paginationSize,

                refreshLocationIndex,
                onGridReady,
                onColumnResized,
                useLocationAsRowNodeId,

                onShowAdd,
                onAddCancel,

                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDeleteLocation,

                onReset,

                user,
                isNotDataAnalyst,
            };
        },
    });
</script>
